import http from './axiosConfig';
import APIError from './customErrors';
// import homePage from '../constants/data/homePage';
// import homeCsatPage from '../constants/data/homeCsatPage';

export const getRevenueReport = async (data) => {
  try {
    const response = await http.post(`/reports/revenue`, data, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw new APIError(error);
  }
};

export const getBookingReport = async (data) => {
  try {
    const response = await http.post(`/reports/bookings`, data, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw new APIError(error);
  }
};

export const getTransactionReport = async (data) => {
  try {
    const response = await http.post(`/reports/transactions`, data, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw new APIError(error);
  }
};

// /reports/homepage
export const getHomePageReport = async (data) => {
  try {
    const response = await http.post(`/reports/homepage`, data, {
      withCredentials: true,
    });
    return response.data;
    // return homePage;
  } catch (error) {
    throw new APIError(error);
  }
};

// /reports/csat
export const getCSATReport = async (data) => {
  try {
    const response = await http.post(`/reports/csat`, data, {
      withCredentials: true,
    });
    return response.data;
    // return homeCsatPage;
  } catch (error) {
    throw new APIError(error);
  }
};
