import { Pagination, Select, createStyles } from '@mantine/core';
import { useMemo } from 'react';

const useStyles = createStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'end',
    padding: theme.spacing.md,
    gap: 20,
    borderTop: '1px solid #dee2e6',
    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
    },
  },
}));

export default function PaginationComponent({
  pageCount,
  gotoPage,
  setPageSize,
  pageSize,
  pageSizeOptions,
  pageIndex,
  hidePageSelect,
}) {
  const { classes } = useStyles();

  const formattedPageSizes = useMemo(
    () => pageSizeOptions.map((option) => String(option)),
    [pageSizeOptions]
  );

  const onChangeHandler = (pageNo) => {
    gotoPage(pageNo - 1);
  };

  const onSelectHandler = (value) => {
    setPageSize(value);
  };

  return (
    <div className={classes.root}>
      {!hidePageSelect && (
        <Select
          value={String(pageSize)}
          data={formattedPageSizes}
          onChange={onSelectHandler}
        />
      )}
      <Pagination
        size="sm"
        total={pageCount}
        boundaries={2}
        siblings={1}
        page={pageIndex + 1}
        onChange={onChangeHandler}
      />
    </div>
  );
}
