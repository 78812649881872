import { t } from '@lingui/macro';
import { TextInput } from '@mantine/core';

export default function SearchFilter({ setGlobalFilter, globalFilter }) {
  const onChangeHandler = (event) => {
    const { value } = event.target;
    setGlobalFilter(value || undefined);
  };

  return (
    <div>
      <TextInput
        value={globalFilter || ''}
        placeholder={t`Search`}
        onChange={onChangeHandler}
      />
    </div>
  );
}
