import { MultiSelect, createStyles } from '@mantine/core';
import { Text } from '@akin/ui-lib';
import { useMemo } from 'react';
import { t, Trans } from '@lingui/macro';

const useStyles = createStyles(() => ({
  root: {
    '& .mantine-MultiSelect-withIcon': {
      paddingLeft: 100,
    },

    '& .mantine-MultiSelect-icon': {
      width: 100,
    },
  },
}));

export default function GroupBySelector({ columns, setGroupBy }) {
  const { classes } = useStyles();

  const data = useMemo(
    () =>
      columns.map((column) => ({
        value: column.accessor,
        label: column.title,
      })),
    [columns]
  );

  return (
    <MultiSelect
      data={data}
      className={classes.root}
      clearable
      searchable
      clearSearchOnBlur
      radius={0}
      onChange={setGroupBy}
      variant="filled"
      placeholder={t`Select headers to group by`}
      dropdownPosition="top"
      size="md"
      icon={
        <Text color="black" size="sm">
          <Trans>Group By:</Trans>
        </Text>
      }
    />
  );
}
