import { t } from '@lingui/macro';
import JsPDF from 'jspdf';
import 'jspdf-autotable';
import XLSX from 'xlsx-js-style';

const HEADER_STYLES = {
  font: {
    bold: true,
  },
  fill: {
    fgColor: { rgb: 'FFECF0E0' },
  },
  alignment: {
    horizontal: 'center',
    vertical: 'center',
  },
};

export function exportExcel({ fileName = t`Report`, data, columns }) {
  const headerColumns = columns.map((column) => column.title);

  const excelData = data.map((row) =>
    columns.reduce((obj, column) => {
      // eslint-disable-next-line no-param-reassign
      obj[column.title] = row[column.accessor];
      return obj;
    }, {})
  );

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(excelData, {
    header: headerColumns,
  });
  XLSX.utils.book_append_sheet(workbook, worksheet);

  // Header styling
  headerColumns.forEach((element, i) => {
    const cellRef = XLSX.utils.encode_cell({ c: i, r: 0 });
    worksheet[cellRef].s = HEADER_STYLES;
  });

  // Apply column styles to all cells in the column
  columns.forEach((column, columnIndex) => {
    if (column.excelStyle) {
      // Start from row 1 (after header) to last row
      const rowCount = excelData.length;
      for (let rowIndex = 1; rowIndex <= rowCount; rowIndex += 1) {
        const cellRef = XLSX.utils.encode_cell({
          c: columnIndex,
          r: rowIndex,
        });
        if (worksheet[cellRef]) {
          worksheet[cellRef].s = column.excelStyle;
        }
      }
    }
  });

  // Set header height
  worksheet['!rows'] = [{ hpx: 30 }];

  // Set column width
  const columnWidths = columns.map((column) => ({
    wch: column?.width || 10,
  }));
  worksheet['!cols'] = columnWidths;

  XLSX.writeFile(workbook, `${fileName}.xlsx`, { compression: true });
}

// eslint-disable-next-line import/prefer-default-export
export function exportPdfTable({
  fileName = t`Report`,
  columns = [],
  data = [],
}) {
  if (!JsPDF) return;

  const pdfBodyData = data.map((rowData) =>
    columns.map((columnDef) => rowData[columnDef.accessor])
  );
  const headers = columns.map((columnDef) => columnDef.title);

  const content = {
    startY: 50,
    head: [headers],
    body: pdfBodyData,
  };

  const unit = 'pt';
  const size = 'A4';
  const orientation = 'landscape';
  const doc = new JsPDF(orientation, unit, size);

  doc.setFontSize(15);
  doc.text(fileName, 40, 40);
  doc.autoTable(content);
  doc.save(`${fileName}.pdf`);
}
