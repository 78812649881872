import { Table, Text, createStyles, Image } from '@mantine/core';
import React from 'react';
import { Trans } from '@lingui/macro';
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useGroupBy,
  useExpanded,
} from 'react-table';
import ICONS from '@akin/core-lib/icons';
import Exports from './exports';
import GroupBySelector from './GroupBySelector';
import Pagination from './Pagination';
import SearchFilter from './SearchFilter';

const useStyles = createStyles((theme) => ({
  root: {
    boxShadow: theme.other.boxShadow.cards,
    borderRadius: theme.radius.sm,
  },
  tableContainer: {
    overflowX: 'auto',
    '& table': {
      wordBreak: 'normal',
      display: 'table',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing.md,
    alignItems: 'center',
  },
  tableLabel: {
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    flexDirection: 'inherit',
    justifyContent: 'flex-start',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
  },
  down: {
    transform: 'rotate(-90deg)',
  },
  up: {
    transform: 'rotate(-270deg)',
  },
}));

function getCellRenderer(cell, row) {
  if (cell.isGrouped) {
    return (
      <>
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? ' ▲' : ' ▼'}
        </span>{' '}
        {cell.render('Cell')} ({row.subRows.length})
      </>
    );
  }
  if (cell.isAggregated) return cell.render('Aggregated');
  if (cell.isPlaceholder) return null;
  return cell.render('Cell');
}

export default function DataTable({
  title,
  data,
  columns,
  options: {
    search = false,
    grouping = false,
    paging,
    pageSizeOptions,
    headerStyle = {},
    cellStyle = {},
    disableSorting = false,
    exportButton = true,
    hidePageSelect = false,
    hideTitle = false,
    allowExport = {},
  },
  exportOptions,
  rowProps = () => ({}),
}) {
  const { classes } = useStyles();
  const tableData = React.useMemo(() => [...data], [data]);

  const tableColumns = React.useMemo(() => [...columns], [columns]);

  const tableInstance = useTable(
    {
      columns: tableColumns,
      data: tableData,
      initialState: {
        pageSize: pageSizeOptions[0],
      },
      disableSortBy: disableSorting,
    },
    useGlobalFilter,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,

    preGlobalFilteredRows,
    setGlobalFilter,
    pageCount,
    gotoPage,
    setPageSize,
    setGroupBy,
    state,
  } = tableInstance;

  const tableRows = paging ? page : rows;

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {!hideTitle && (
          <Text size="md" lineClamp={1}>
            {title}
          </Text>
        )}
        <div className={classes.actions}>
          {search && (
            <SearchFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              setGlobalFilter={setGlobalFilter}
              globalFilter={state.globalFilter}
            />
          )}

          {exportButton && (
            <Exports
              title={title}
              data={data}
              columns={tableColumns}
              options={exportOptions}
              allowExport={allowExport}
            />
          )}
        </div>
      </div>

      {grouping && (
        <GroupBySelector columns={columns} setGroupBy={setGroupBy} />
      )}

      <div className={classes.tableContainer}>
        <Table horizontalSpacing="md" verticalSpacing="md" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps([
                      { style: { ...headerStyle, ...column.style } },
                      column.getSortByToggleProps(),
                    ])}
                  >
                    <span className={classes.tableLabel}>
                      <div>{column.render('title')}</div>
                      <span
                        style={{
                          visibility: column.isSorted ? 'visible' : 'hidden',
                          paddingLeft: 8,
                        }}
                      >
                        {column.isSortedDesc ? (
                          <Image
                            height={10}
                            width={10}
                            className={classes.down}
                            src={ICONS.left}
                          />
                        ) : (
                          <Image
                            height={10}
                            width={10}
                            className={classes.up}
                            src={ICONS.left}
                          />
                        )}
                      </span>
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {tableRows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps(rowProps(row))}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps([{ style: { ...cellStyle } }])}>
                      {getCellRenderer(cell, row)}
                    </td>
                  ))}
                </tr>
              );
            })}

            {tableRows.length === 0 && (
              <tr style={{ height: 300, textAlign: 'center' }}>
                <td colSpan={columns.length}>
                  <Text size="md" color="dimmed">
                    <Trans>No records to display</Trans>
                  </Text>
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        {paging && (
          <Pagination
            pageCount={pageCount}
            gotoPage={gotoPage}
            setPageSize={setPageSize}
            pageSize={state.pageSize}
            pageIndex={state.pageIndex}
            pageSizeOptions={pageSizeOptions}
            hidePageSelect={hidePageSelect}
          />
        )}
      </div>
    </div>
  );
}
