import { Menu, IconButton, Text, Image } from '@akin/ui-lib';
import { Trans } from '@lingui/macro';
import React from 'react';
import ICONS from '@akin/core-lib/icons';
import { exportExcel, exportPdfTable } from './helper';

export default function Exports({
  title,
  columns = [],
  data = [],
  options,
  allowExport = { excel: true, pdf: true },
}) {
  const exportColumns = options?.columns || columns;

  const exportCSVHandler = () => {
    exportExcel({ fileName: title, data, columns: exportColumns });
  };

  const exportPDFHandler = () => {
    exportPdfTable({ fileName: title, columns: exportColumns, data });
  };

  return (
    <Menu shadow="lg" ml="lg">
      <Menu.Target>
        <IconButton radius="xl" ml="md">
          <Image src={ICONS.download} />
        </IconButton>
      </Menu.Target>
      <Menu.Dropdown>
        {allowExport.excel && (
          <Menu.Item onClick={exportCSVHandler}>
            <Text size="md">
              <Trans>Export as Excel</Trans>
            </Text>
          </Menu.Item>
        )}
        {allowExport.pdf && (
          <Menu.Item>
            <Text size="md" onClick={exportPDFHandler}>
              <Trans>Export as PDF</Trans>
            </Text>
          </Menu.Item>
        )}
      </Menu.Dropdown>
    </Menu>
  );
}
